import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/cache-policies",
  "date": "28 May 2021",
  "title": "Cloudfront, caching & Skpr",
  "summary": "Cloudfront + Skpr is a match made in heaven",
  "author": "Karl Hepworth",
  "tag": "Cache",
  "tagColor": "blue",
  "tags": [{
    "name": "skpr"
  }, {
    "name": "cache"
  }, {
    "name": "cloudfront"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Skpr now supports managed CloudFront cache policies!`}</p>
    <p>{`In this blog, I'll be explaining what they are, how they work and how you can
configure a project on Skpr to use them.`}</p>
    <h2>{`Background`}</h2>
    <p>{`If your website starts to send errors to users, wouldn't it make sense to
send the user the last known good piece of content while the error is present?`}</p>
    <p>{`This is one example of using a cache layer - it acts as a small Content Delivery
Network for your application which will store and respond with your content
with a couple of constraints such as time.`}</p>
    <p>{`What is more problematic is when error pages make it into this 'bucket' and
continue to serve even after the error on your website has gone away. A
cache policy would allow the application to dynamically check and serve
content accordingly if the error is gone before serving the content in the
cache.`}</p>
    <h2>{`Monitoring an endpoint`}</h2>
    <p>{`To test the cache on an endpoint, we will need a way to monitor for changes
as they happen. For this, we can run a shell script that will poll
an endpoint at regular intervals.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`#!/bin/bash

while true
do
    echo $(curl --HEAD -s \${ENDPOINT} | grep "x-cache");
    sleep 5
done
`}</code></pre>
    <p>{`You can see this in action below:`}</p>
    <p><img parentName="p" {...{
        "src": "/images/posts/20210526/step01.gif",
        "alt": "Animated image showing the output of a script polling a website for an x-cache\nheader showing an endpoint entering CloudFront's cache"
      }}></img></p>
    <p>{`You'll notice when the endpoint isn't cached it'll return a `}<inlineCode parentName="p">{`Miss`}</inlineCode>{` before
continuing to a `}<inlineCode parentName="p">{`Hit`}</inlineCode>{` and continuing to `}<inlineCode parentName="p">{`Hit`}</inlineCode>{`. This means that the content at
the endpoint was cached.`}</p>
    <p>{`If we were to force an error to occur, we will be able to see the response
change to a 'RefreshHit'.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/posts/20210526/step02.gif",
        "alt": "Animated image showing the output of a script polling a website for an x-cache\nheader showing an endpoint leaving and entering CloudFront's cache before\nleaving and serving stale content"
      }}></img></p>
    <p>{`This is the behaviour we want to see, but to get this we'll need a couple of
settings to be configured.`}</p>
    <h2>{`How it works`}</h2>
    <p>{`So in order to roll out standardized configuration we'll be using a couple of
Cache Policies in our CDN - CloudFront. These cache policies allow us to set
the value of our Minimum TTL in a standardized and managed way.`}</p>
    <p>{`The Minimum TTL is how long the cache object will stay in CloudFront as a
minimum before fetching a new object from the origin endpoint. The content
will first work its way into a separate bucket in the event it needs to be
served as a `}<inlineCode parentName="p">{`RefreshHit`}</inlineCode>{`, but it will naturally expire after the quantity of
this field in seconds and be replaced as the cache lifecycle happens.`}</p>
    <p>{`It's worth noting that the Minimum TTL respects the `}<inlineCode parentName="p">{`Max-Age`}</inlineCode>{` header response of
the application. CloudFront takes care of the heavy lifting from the headers
of your application when the value isn't set to `}<inlineCode parentName="p">{`0`}</inlineCode>{`. Think of this as a
way of unlocking the functionality of CloudFront rather than depending on the
application to configure the CDN.`}</p>
    <h2>{`See it in action!`}</h2>
    <p>{`We're showing you (below) the behaviour if the cached object were to be
invalidated, and the error goes into the service's cache. Following this, we
resolve the error, and the object gets purged and replaced by the new object.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/posts/20210526/step03.gif",
        "alt": "Animated image showing the output of a script polling a website for an\nx-cache header showing an endpoint with a cached error recovering and caching a\nfresh copy of content that isn't throwing an error"
      }}></img></p>
    <p>{`For our final demonstration, we'll show a working cache endpoint responding with
an initially uncached object which goes into the cache, which then starts
throwing a 503 error that is quickly resolved.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/posts/20210526/step04.gif",
        "alt": "Animated image showing the output of a script polling a website for an\nx-cache header showing the full lifecycle of a cached endpoint. First entering\ncache, then expiring and being refreshed. Following this, the cache becomes\nstale, being cleared out of cache and being replaced by a fresh piece of\nnon-erroring content."
      }}></img></p>
    <h2>{`How you can use it`}</h2>
    <p>{`We've got a couple of `}<a parentName="p" {...{
        "href": "https://docs.skpr.io/cache/"
      }}>{`managed policies`}</a>{` which
you can use on Skpr. You can find more information on the official docs.`}</p>
    <p>{`You can opt-into one of these using the example configuration below,
substituting `}<inlineCode parentName="p">{`drupal`}</inlineCode>{` for one of the managed cache policies we've created.`}</p>
    <pre><code parentName="pre" {...{}}>{`ingress:
  cache:
    policy: drupal
`}</code></pre>
    <h2>{`Final notes`}</h2>
    <p>{`It's a great idea to consider opting into using this feature ahead of
becoming the default. It's an option that could potentially improve your
uptime and improve the experience for your users.`}</p>
    <p>{`Have a look at the `}<a parentName="p" {...{
        "href": "https://docs.skpr.io/cache/"
      }}>{`Skpr docs`}</a>{` and see if
there's a cache policy available that will help you. If you need something
more specific to your needs reach out! `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      